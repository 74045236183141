import {
  arrayIncludes,
  iterate2DimensionalArrayCombinations,
} from "@microsite/lib/utils/arrayUtils";
import {
  Product,
  ProductOptionValueSelector,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";

const getAvailableProductOptionMaps = (product?: Product) => {
  const productOptionMaps = product?.shopifyProduct?.productOptionMaps;

  return productOptionMaps
    ?.filter(
      ({ productVariant }) =>
        !!productVariant.promotable && !productVariant.isOutOfStock,
    )
    ?.map(({ productOptionValueIds }) => productOptionValueIds);
};

const getProductOptionValues = (product?: Product) => {
  const productOptions = product?.shopifyProduct?.productOptions;

  return productOptions?.map((productOption) => {
    if (productOption.selector === "color_swatch") {
      return productOption.productOptionValues
        .filter((option) => {
          if (option.assetType === ProductOptionValueSelector.Color)
            return !!option.hexColor;
          if (option.assetType === ProductOptionValueSelector.Image)
            return !!option.media?.mediaId;

          return true;
        })
        .map(({ id }) => id);
    }

    return productOption.productOptionValues.map(({ id }) => id);
  });
};

// returns ids of possible combinations of variants that have stock and are promotable;
// e.g.: blue + small for a T-Shirt
const getAllowedVariantCombinations = (
  optionValues?: string[][],
  availableProductOptionMaps?: string[][],
) => {
  if (!optionValues || !availableProductOptionMaps) return [];

  const allowedVariantCombinations: string[][] = [];
  const iterationCallback = (combination: string[]) => {
    if (arrayIncludes(availableProductOptionMaps, combination))
      allowedVariantCombinations.push(combination);
  };
  iterate2DimensionalArrayCombinations(optionValues, 0, [], iterationCallback);

  return allowedVariantCombinations;
};

const getDefaultOptionsValueIds = (product?: Product): string[] | undefined => {
  if (!product) return;

  const optionValues = getProductOptionValues(product);
  const availableProductOptionMaps = getAvailableProductOptionMaps(product);

  const allowedVariantCombinations = getAllowedVariantCombinations(
    optionValues,
    availableProductOptionMaps,
  );
  const firstAllowedVariantCombination = allowedVariantCombinations[0];

  return firstAllowedVariantCombination;
};

export default getDefaultOptionsValueIds;
