import {
  PartialStory,
  PersonalizationSection,
  PersonalizationSectionStories,
} from "@utils/microsites/types/personalization";
import PLACEHOLDER_STORY from "@utils/placeholders/story";

export const findStoriesSection = (
  sections: PersonalizationSection[],
): PersonalizationSectionStories | undefined => {
  return sections.find(
    (section) =>
      section.__typename === "PersonalizationsMicrositeSectionsStories",
  ) as PersonalizationSectionStories | undefined;
};

const pickSectionStories = (
  sectionData: PersonalizationSectionStories | undefined | null,
): PartialStory[] => {
  if (!sectionData) {
    return [];
  }

  const mapAllStories = Object.fromEntries(
    [sectionData.libraryCustomer, sectionData.libraryMerchant]
      .flat()
      .map((story) => [story?.uuid, story]),
  );

  const pickedStories = sectionData.libraryPickedUuids
    ?.map((uuid) => mapAllStories[uuid])
    .filter((story) => !!story);

  if (pickedStories?.length) return pickedStories;

  return sectionData.libraryMerchant || [PLACEHOLDER_STORY];
};

export default pickSectionStories;
