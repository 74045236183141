import {
  BaseValueUnit,
  Unit,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";

/**
 * Calculate the given price with discount
 *
 * @param price - The price to calculate
 * @param discount - The discount to apply
 */
const priceCalculatorWithDiscount = (
  price: number,
  discount: BaseValueUnit | null | undefined,
) => {
  if (!discount) return price;

  if (discount.unit === Unit.Percentage) {
    return Math.max(price - (price * discount.value) / 100, 0);
  }

  return Math.max(price - discount.value, 0);
};

export default priceCalculatorWithDiscount;
