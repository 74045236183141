export enum DynamicVariation {
  DEFAULT = "default",
  FULLSCREEN = "fullscreen",
  MULTICOLUMN = "multicolumn",
  MEDIA = "media",
}

export enum MarqueeVariation {
  TEXT = "text",
  COBRANDED = "cobranded",
}

export enum MediaWithTextVariation {
  SIDE_BY_SIDE = "side_by_side",
  GRID = "grid",
}

export enum ProductVariation {
  DEFAULT = "default",
  GRID_STYLE_1 = "grid",
  GRID_STYLE_2 = "grid2",
}

export enum ReviewVariation {
  CLASSIC = "classic",
  EXPERTS = "experts",
  EXPERT_BRANDS = "expert_brands",
  EXPERTS_SHOWCASE = "experts_showcase",
}

export enum ShowcaseImagesVariation {
  MARQUEE = "marquee",
  GRID = "grid",
}

export enum StoriesVariation {
  INSTAGRAM = "instagram",
  CAROUSEL = "carousel",
  BASIC = "basic",
}

export enum TextVariation {
  SINGLE = "single",
  MULTI_COLUMN = "multi_column",
}

export enum VideoVariation {
  DEFAULT = "default",
  CAROUSEL = "carousel",
}
