/* eslint-disable @typescript-eslint/no-explicit-any, no-console */

// If this is executing on the Storefront context, we can use its loggers.
// Otherwise we fallback to console.log.
// TODO: Do something similar for the Portal/Microsite/... contexts,
// so we can always import/use the loggers the same way.
let storefrontGlobals = undefined;

if (typeof window !== "undefined") {
  storefrontGlobals = (window as any)?.$superfiliateStorefront as unknown as
    | undefined
    | {
        logging?: {
          reportError?: (possibleError: unknown) => void;
          customConsoleLog?: (...props: unknown[]) => void;
          debugConsoleLog?: (...props: unknown[]) => void;
        };
      };
}

export const reportError =
  storefrontGlobals?.logging?.reportError || console.error;
export const customConsoleLog =
  storefrontGlobals?.logging?.customConsoleLog || console.log;
export const debugConsoleLog =
  storefrontGlobals?.logging?.debugConsoleLog || console.log;
