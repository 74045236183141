"use client";

import { createContext, FC, useEffect, useState } from "react";

import { useProducts } from "@microsite/lib/api/products/fetch";
import findVariant from "@microsite/lib/productOptions/findVariant";
import getDefaultOptionsValueIds from "@microsite/lib/productOptions/getDefaultOptionsValueIds";
import useCartState from "@microsite/state/cart";
import {
  BuyModalities,
  Maybe,
  Product,
  ShopifyProductVariant,
  ShopifySellingPlan,
  ShopifySellingPlanGroup,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { BUYING_MODALITIES, OrderInterval } from "@utils/types";

import type { CartSettingsDefault } from "@microsite/state/cart/types";

interface UpsellProductsContextProps {
  pickedProducts?: Product[];

  product?: Product | null;
  variant: ShopifyProductVariant | undefined;
  options: string[] | undefined;
  sellingOption: OrderInterval | undefined;
  sellingPlanGroup: Maybe<ShopifySellingPlanGroup> | undefined;
  sellingPlan: ShopifySellingPlan | undefined;
  pickedProductId: string | undefined;

  setProduct: (product: Product) => void;
  setVariant: (variant: ShopifyProductVariant) => void;
  setOptions: (options: string[]) => void;
  setSellingOption: (option: OrderInterval) => void;
  setSellingPlan: (sellingPlan: ShopifySellingPlan | undefined) => void;
  setPickedProductId: (id: string | undefined) => void;

  getSellingOptions?: (product?: Product) => OrderInterval | undefined;
}

export const UpsellProductsContext = createContext<UpsellProductsContextProps>({
  /* Products loaded from the microsite template, picked by the end-customer */
  pickedProducts: [],

  /* Product ID selected by the customer, if multiple */
  pickedProductId: undefined,
  /* Product ID setter */
  setPickedProductId: (_: string | undefined) => {
    /**/
  },

  /* Product currently selected and loaded on the page */
  product: undefined,
  /* Product setter */
  setProduct: (_: Product) => {
    /**/
  },

  /* Variant currently selected and loaded on the page */
  variant: undefined,
  /* Variant setter */
  setVariant: (_: ShopifyProductVariant) => {
    /**/
  },

  /* Options currently selected, that maps to the variant above */
  options: undefined,
  /* Options setter */
  setOptions: (_: string[]) => {
    /**/
  },

  /* Selling Option currently selected, that maps to either subscription or 1-time */
  sellingOption: undefined,
  /* Selling Option setter */
  setSellingOption: (_: OrderInterval) => {
    /**/
  },

  /* The Selling Plan Group set for the product or variant */
  sellingPlanGroup: undefined,
  /* The Selling Plan selected, which defines the subscription cadence */
  sellingPlan: undefined,
  /* Selling Plan setter */
  setSellingPlan: (_: ShopifySellingPlan | undefined) => {
    /**/
  },
  /**
   * Return the selling options of a given product.
   * If no product given in the parameters, it will use
   * the current product in the context state
   */
  getSellingOptions: (_?: Product) => {
    return undefined;
  },
});

/**
 * This provider contains all the product and variant related details, so they
 * can be used by all the surrounding components of the app
 */
export const UpsellProductsProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [product, setProduct] = useState<Product>();
  const [variant, setVariant] = useState<ShopifyProductVariant>();
  const [options, setOptions] = useState<string[]>();
  const [sellingOption, setSellingOption] = useState<OrderInterval>();
  const [sellingPlan, setSellingPlan] = useState<
    ShopifySellingPlan | undefined
  >();
  const [pickedProductId, setPickedProductId] = useState<string>();

  const sellingPlanGroup =
    product?.shopifyProduct?.shopifySellingPlanGroup ||
    variant?.shopifySellingPlanGroup;

  const cartSettings = useCartState(
    (state) => state.settings,
  ) as CartSettingsDefault;

  useEffect(() => {
    const firstOptions = getDefaultOptionsValueIds(product);
    const firstVariant = findVariant(
      firstOptions || [],
      product?.shopifyProduct,
    );

    setVariant(firstVariant);
    setOptions(firstVariant?.productOptionMap?.productOptionValueIds);
  }, [product]);

  const getSellingOptions = (product?: Product) => {
    const buyModalities = product?.buyModalities || BuyModalities.AllPossible;

    const hasSubscription = Boolean(
      BUYING_MODALITIES.subscription.includes(buyModalities) &&
        sellingPlanGroup,
    );

    return hasSubscription
      ? OrderInterval.SUBSCRIPTION
      : OrderInterval.ONE_TIME;
  };

  useEffect(
    () => {
      setSellingOption(getSellingOptions());
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant],
  );

  useEffect(
    () => {
      const updatedVariant = findVariant(
        options || [],
        product?.shopifyProduct,
        true,
      );

      const mappedIds = updatedVariant?.productOptionMap?.productOptionValueIds;

      /* This validation is required to select the correct variant on the UI */
      if (mappedIds !== options) return setOptions(mappedIds);
      if (updatedVariant) setVariant(updatedVariant);
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  useEffect(() => {
    setSellingPlan(sellingPlanGroup?.shopifySellingPlans?.[0]);
  }, [sellingPlanGroup]);

  const pickedProductIds = cartSettings?.availableProductIds || [];

  const productsListRequest = useProducts(pickedProductIds);

  const pickedProducts = (productsListRequest?.data?.products?.nodes ||
    []) as Product[];

  useEffect(
    () => {
      if (!pickedProductId) setPickedProductId(pickedProducts?.[0]?.id);
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pickedProducts],
  );

  return (
    <UpsellProductsContext.Provider
      value={{
        pickedProducts,
        pickedProductId,
        setPickedProductId,
        product,
        setProduct,
        variant,
        setVariant,
        options,
        setOptions,
        sellingOption,
        setSellingOption,
        sellingPlanGroup,
        sellingPlan,
        setSellingPlan,
        getSellingOptions,
      }}
    >
      {children}
    </UpsellProductsContext.Provider>
  );
};
