import isEqual from "lodash/isEqual";

import { GiftingProductVariant } from "@microsite/types/pages/gift";
import { ShopifyProduct } from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { RelatedProductVariant } from "@utils/microsites/types/microsite";

/**
 * Receives a shopifyProduct instance and finds the specific variant based on
 * the selected options
 *
 * It also includes a fallback logic that will try to find the variant
 * based on the selected options minus the last one, and so on, until
 * it finds a variant or there are no more options to try
 *
 * This is necessary because the productOptionMaps are not always
 * created for all the possible combinations of options, so we need
 * to try to find the variant based on the options that are available
 * for the shopifyProduct
 *
 * @param optionValues - The selected options
 * @param shopifyProduct - The shopifyProduct model
 * @param fallback - Whether to use the fallback logic
 */
const findVariant = (
  optionValues: string[],
  shopifyProduct?: ShopifyProduct | null,
  fallback?: boolean,
  availableVariants?: GiftingProductVariant[] | null,
): RelatedProductVariant | undefined => {
  if (!shopifyProduct?.productOptionMaps) {
    return;
  }

  const availableOptionMaps = shopifyProduct.productOptionMaps?.filter(
    (option) =>
      availableVariants
        ?.map((variant) => variant.id)
        .includes(option.productVariant.id),
  );

  const optionMaps = availableVariants?.length
    ? availableOptionMaps
    : shopifyProduct?.productOptionMaps;

  const variants = availableVariants?.length
    ? availableVariants
    : shopifyProduct.productVariants;

  const optionValuesSet = new Set(optionValues);
  const productOptionMap = optionMaps.find(({ productOptionValueIds }) => {
    if (productOptionValueIds.length === optionValues.length) {
      const productOptionValueIdsSet = new Set(productOptionValueIds);

      return isEqual(optionValuesSet, productOptionValueIdsSet);
    } else {
      return optionValues.every((id) => productOptionValueIds.includes(id));
    }
  });

  const variantId = productOptionMap?.productVariant?.id;

  const variant = variants.find((variant) => variant.id === variantId);

  if (variant || !fallback) return variant as RelatedProductVariant;

  const reducedList = optionValues.slice(0, -1);

  if (reducedList.length === 0) return;

  return findVariant(reducedList, shopifyProduct, fallback, availableVariants);
};

export default findVariant;
