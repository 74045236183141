import { getCookie, setCookie } from "@utils/cookies";
import getMainDomain from "@utils/routes/getMainDomain";
import getUUID from "@utils/uuidHelpers";

export const SF_MICROSITE_SESSION_KEY = "sf_microsite_session";

const useAttributionWindow = () => {
  const setPageAccessCookie = () => {
    const uuid = getCookie(SF_MICROSITE_SESSION_KEY) || getUUID();
    const domain = getMainDomain();
    const expires = 30; // 30 days from now

    setCookie(SF_MICROSITE_SESSION_KEY, uuid, {
      domain,
      expires,
    });
  };

  const sendPageAccessEvent = async (shopId: string, code: string) => {
    const uuid = getCookie(SF_MICROSITE_SESSION_KEY);
    if (!uuid || !shopId) return;

    const params = new FormData();
    params.append("uuid", uuid);
    params.append("shop_id", shopId);
    params.append("user_agent", navigator.userAgent);
    params.append("event_type", "microsite_access");
    params.append("code", code);
    params.append("document_referrer", document.referrer);
    navigator.sendBeacon("/api/events", params);
  };

  return { setPageAccessCookie, sendPageAccessEvent };
};

export default useAttributionWindow;
