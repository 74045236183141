"use client";

import { createContext, FC } from "react";

import { PersonalizationSectionWithPseudoId } from "@utils/microsites/personalization/orderMicrositeSections";

interface SectionContextProps extends React.PropsWithChildren {
  section: PersonalizationSectionWithPseudoId | undefined;
}

export const SectionContext = createContext<SectionContextProps>({
  /* The section currently being rendered */
  section: undefined,
});

/**
 * This provider contains information of the currently selected
 * section from the microsite
 *
 * It is intended to be used by the components that are rendered
 * loading a microsite template
 */
export const SectionProvider: FC<SectionContextProps> = ({
  children,
  section,
}) => (
  <SectionContext.Provider value={{ section }}>
    {children}
  </SectionContext.Provider>
);
