import Cookies from "js-cookie";

export type CookieAttributes = Cookies.CookieAttributes;

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export const setCookie = (
  key: string,
  value: string,
  options?: CookieAttributes,
) => {
  return Cookies.set(key, value, options);
};

export const deleteCookie = (key: string, options?: CookieAttributes) => {
  return Cookies.remove(key, options);
};
