let currency = "USD";
export const setCurrency = (newCurrency: string) => {
  currency = newCurrency;
};
export const getCurrency = () => currency;

// The browser decides to format the same currency differently based on the locale.
// If you were seeing USD from `en-US`, it would be shown as `$ 1,234.56`,
// If you were seeing USD from `pt-BR`, it would be shown as `US$ 1.234,56`.
// This keeps the formatting independent of the visitor location, always the same set locale for each.
const CURRENCY_LOCALE_MAP: Record<string, string | undefined> = {
  AUD: "en-AU", // `$1,234.56`
  BRL: "pt-BR", // `R$ 1,234.56`
  CAD: "en-CA", // `$1,234.56`
  EUR: "en-EU", // `€1,234.56`
  GBP: "en-GB", // `£1,234.56`
  MXN: "es-MX", // `$1,234.56`
  NZD: "en-NZ", // `$1,234.56`
  SEK: "sv-SE", // `1.234,56 kr`
  USD: "en-US", // `$1,234.56`
};
const getLocale: () => string = () => CURRENCY_LOCALE_MAP[currency] || "en-US";

const formatter = () =>
  new Intl.NumberFormat(getLocale(), { style: "currency", currency });

const compactFormatter = () =>
  new Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency,
    notation: "compact",
  });

export const parse = (value: string | number | undefined | null): number =>
  parseFloat((value ? `${value}` : "0").replace("$", ""));

export const fullFormat = (value: number | string | undefined | null): string =>
  formatter().format(parse(value));

export const shortFormat = (
  value: number | string | undefined | null,
): string => fullFormat(value).replace(/\.\d+/, "");

export const compactFormat = (
  value: number | string | undefined | null,
): string => compactFormatter().format(parse(value));

export const percentageFormat = (value: number): string =>
  (value * 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) + "%";

const methods = {
  parse,
  fullFormat,
  shortFormat,
  percentageFormat,
};
export default methods;
