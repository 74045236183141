"use client";

import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import {
  mapToCustomerSettingStories,
  pickSectionStories,
} from "@utils/microsites/stories";
import { StoreOnboardingSettingsContextValues } from "@utils/microsites/types/onboarding";
import { Sections } from "@utils/microsites/types/personalization";
import { createContext, FC, useContext, useState } from "react";

import { CampaignSuperfiliateMicrositeContext } from "./campaignSuperfiliate";

export const defaultStoreOnboardingSettings = {
  defaultStories: [],
  stories: [],
};

export const StoreOnboardingSettingsContext =
  createContext<StoreOnboardingSettingsContextValues>({
    storeSettings: defaultStoreOnboardingSettings,
    setStoreSettings: () => {
      /**/
    },
  });

/**
 * This context is intended to be used for templates that have the
 * digital builder editor, so we present the shop's customized page style
 */
export const StoreOnboardingSettingsContextProvider: FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const storeSettingsContext = useContext(StoreOnboardingSettingsContext);
  const campaignSuperfiliateMicrosite = useContext(
    CampaignSuperfiliateMicrositeContext,
  );
  const storiesSection = findMicrositeSection(
    campaignSuperfiliateMicrosite.personalization,
    Sections.Stories,
  );

  const stories =
    mapToCustomerSettingStories(pickSectionStories(storiesSection)) ||
    storeSettingsContext?.storeSettings?.stories ||
    defaultStoreOnboardingSettings.stories;

  const defaultStories =
    mapToCustomerSettingStories(storiesSection?.libraryMerchant || []) ||
    storeSettingsContext?.storeSettings?.defaultStories ||
    defaultStoreOnboardingSettings.defaultStories;

  const [storeSettings, setStoreSettings] = useState({
    ...(storeSettingsContext?.storeSettings || defaultStoreOnboardingSettings),

    defaultStories,
    stories,
  });

  return (
    <StoreOnboardingSettingsContext.Provider
      value={{ storeSettings, setStoreSettings }}
    >
      {children}
    </StoreOnboardingSettingsContext.Provider>
  );
};
