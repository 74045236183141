import useSWR from "swr";

import getMicrositeProducts from "@microsite/serverActions/getMicrositeProducts";

export const useProducts = (
  ids: (string | number | undefined | null)[] = [],
) => {
  const formattedIds = ids.filter((id) => !!id).map((id) => `${id}`);

  const isPaused = formattedIds.length === 0;
  const swrResult = useSWR(
    isPaused
      ? null // `null` pauses the data-fetching.
      : ["getMicrositeProducts", formattedIds],
    ([_, ...args]) => getMicrositeProducts(...args),
    {
      dedupingInterval: 60000,
      revalidateOnFocus: false,
    },
  );

  return {
    ...swrResult,
    data: swrResult.data,
  };
};
