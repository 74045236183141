function isPresent<T>(value: T | null | undefined): value is T {
  if (value === null || value === undefined) return false;

  return true;
}

const compactArray = <T>(array: (T | null | undefined)[]): T[] =>
  array.filter(isPresent);

const compactHash = (hash: Record<string, unknown>): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(hash).filter(([_key, value]) => isPresent(value)),
  );

function compact<T>(
  object: (T | null | undefined)[] | null | undefined,
): T[] | null;
function compact<T>(
  object: Record<string, T | null | undefined> | null | undefined,
): Record<string, T> | null;
function compact(object: unknown) {
  if (!isPresent(object)) return null;
  if (Array.isArray(object)) return compactArray(object);

  return compactHash(object as Record<string, unknown>);
}

export default compact;
