import {
  Personalization,
  PersonalizationSection,
  PersonalizationSectionAccordion,
  PersonalizationSectionDynamic,
  PersonalizationSectionHeader,
  PersonalizationSectionImage,
  PersonalizationSectionMarquee,
  PersonalizationSectionMediaWithText,
  PersonalizationSectionProduct,
  PersonalizationSectionReview,
  PersonalizationSectionShowcaseImages,
  PersonalizationSectionStories,
  PersonalizationSectionText,
  PersonalizationSectionVideo,
  Sections,
} from "../types/personalization";

/**
 * Dig into the microsites query from the external GraphQL and find the
 * first occurence of the given section type
 *
 * @param personalization - The microsite personalization object, from GraphQL
 * @param key - The section name
 * @param index - The section index on the section order
 */
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Accordion,
  index?: number,
): PersonalizationSectionAccordion | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Dynamic,
  index?: number,
): PersonalizationSectionDynamic | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Header,
  index?: number,
): PersonalizationSectionHeader | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Footer,
  index?: number,
): PersonalizationSectionHeader | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Image,
  index?: number,
): PersonalizationSectionImage | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Marquee,
  index?: number,
): PersonalizationSectionMarquee | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.MediaWithText,
  index?: number,
): PersonalizationSectionMediaWithText | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Product,
  index?: number,
): PersonalizationSectionProduct | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Review,
  index?: number,
): PersonalizationSectionReview | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.ShowcaseImages,
  index?: number,
): PersonalizationSectionShowcaseImages | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Stories,
  index?: number,
): PersonalizationSectionStories | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Text,
  index?: number,
): PersonalizationSectionText | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections.Video,
  index?: number,
): PersonalizationSectionVideo | undefined | null;
function findMicrositeSection(
  personalization: Personalization | undefined | null,
  key: Sections,
  index?: number,
): PersonalizationSection | undefined | null {
  const personalizationSections = personalization?.microsite?.sections || [];
  const sections = personalizationSections.filter(
    (section) => section.__typename === key,
  );

  const section = sections[index || 0];

  return section || null;
}

export default findMicrositeSection;
