"use client";

export {
  AssetContent,
  FormatEnum,
  Theme as PersonalizationSectionTheme,
  PositionEnum,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";

export { Apps, Sections } from "./sections";

import { CommonMedia } from "@utils/types";

import { CampaignSuperfiliateMicrosite } from "../microsite";

export type Personalization = NonNullable<
  CampaignSuperfiliateMicrosite["personalization"]
>;

export type PersonalizationMicrosite = NonNullable<
  Personalization["microsite"]
>;

export type PersonalizationBranding = NonNullable<Personalization["branding"]>;

export type PersonalizationSection = PersonalizationMicrosite["sections"][0];
export type PersonalizationApp = PersonalizationMicrosite["apps"][0];

export interface PartialStory {
  uuid: string;
  alt: string;
  url: string;
  contentType: string;
  duration?: number | null;
  thumbnailUrl?: string | null;
  media?: CommonMedia | null;
}

export type PersonalizationSectionAccordion = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsAccordion" }
>;
export type PersonalizationSectionDynamic = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsDynamic" }
>;
export type PersonalizationSectionHeader = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsHeader" }
>;
export type PersonalizationSectionFooter = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsFooter" }
>;
export type PersonalizationSectionImage = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsImage" }
>;
export type PersonalizationSectionMarquee = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsMarquee" }
>;
export type PersonalizationSectionMediaWithText = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsMediaWithText" }
>;
export type PersonalizationSectionProduct = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsProduct" }
>;
export type PersonalizationSectionReview = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsReview" }
>;
export type PersonalizationSectionShowcaseImages = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsShowcaseImages" }
>;
export type PersonalizationSectionStories = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsStories" }
>;
export type PersonalizationSectionText = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsText" }
>;
export type PersonalizationSectionVideo = Extract<
  PersonalizationSection,
  { __typename: "PersonalizationsMicrositeSectionsVideo" }
>;

export type CommonPersonalizationSectionKeys = {
  [K in keyof PersonalizationSection]: PersonalizationSection[K];
};

export type PersonalizationAppCart = Extract<
  PersonalizationApp,
  { __typename: "PersonalizationsMicrositeAppsCart" }
>;

export type PersonalizationAppPromotion = Extract<
  PersonalizationApp,
  { __typename: "PersonalizationsMicrositeAppsPromotion" }
>;

export type PersonalizationAppStickyAddToCart = Extract<
  PersonalizationApp,
  { __typename: "PersonalizationsMicrositeAppsStickyAddToCart" }
>;
