import { create } from "zustand";

import { CampaignSuperfiliate } from "@superfiliate/graphql-sdk/src/lib/__generated__";

type SuperfiliateState = Partial<CampaignSuperfiliate>;

interface SuperfiliateActions {
  /* Set the Superfiliate payload */
  setSuperfiliate: (input: SuperfiliateState) => void;
}

const useSuperfiliateState = create<SuperfiliateState & SuperfiliateActions>()(
  (set) => ({
    setSuperfiliate: (input) => set(() => input),
  }),
);

export default useSuperfiliateState;
