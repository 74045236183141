import { SocialItemTypes } from "../types";

export const COMMON_EVENTS = {
  CODE_SHARED: "Code Shared",
  CODE_GENERATED: "Code Generated",
};

export const MICROSITE_EVENTS = {
  MICROSITE_ACCESSED: "Microsite Accessed",
  MICROSITE_OPT_IN_ACCESSED: "Microsite Opt-in Accessed",
};

export const PORTAL_EVENTS = {
  LOGIN_EMAIL_SUBMITTED: "Portal login email submitted",
  LOGIN_EMAIL_FAIL: "Portal login email fail",
  LOGIN_EMAIL_SUCCESS: "Portal login email success",
  LOGIN_OTP_SUBMITTED: "Portal login OTP submitted",
  LOGIN_OTP_FAIL: "Portal login OTP fail",
  LOGIN_OTP_SUCCESS: "Portal login OTP success",
};

export const PROGRAM_WIDGET_EVENTS = {
  PROGRAM_WIDGET_OPENED: "Program Widget Opened",
};

export type ShareTarget = SocialItemTypes | "copy" | "native";
