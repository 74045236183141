import { CampaignSuperfiliateMicrositeContext } from "./campaignSuperfiliate";
import {
  CustomerOnboardingCustomizationContext,
  CustomerOnboardingCustomizationContextProvider,
  defaultCustomerOnboardingSettings,
} from "./customerOnboarding";
import {
  StoreOnboardingSettingsContext,
  StoreOnboardingSettingsContextProvider,
} from "./shopOnboarding";

export type { CustomerSettings } from "./customerOnboarding";

export {
  /* Admin Contexts */
  CampaignSuperfiliateMicrositeContext,
  CustomerOnboardingCustomizationContext,
  CustomerOnboardingCustomizationContextProvider,
  /* Customer Contexts */
  defaultCustomerOnboardingSettings,
  StoreOnboardingSettingsContext,
  StoreOnboardingSettingsContextProvider,
};
