import priceCalculator from "@microsite/features/product/priceCalculator";
import {
  BaseValueUnit,
  ShopifyProductVariant,
  ShopifySellingPlan,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";

interface UsePriceProps {
  variant: ShopifyProductVariant | undefined;
  sellingPlan: ShopifySellingPlan | undefined;
  discount?: BaseValueUnit | null;
}

/**
 * This hook centralizes all the pricing calculations for variants, with
 * or without subscription components
 *
 * It also applies the given lead discount defined for a microsite
 */
const usePrice = (params: UsePriceProps) => {
  return priceCalculator(params);
};

export default usePrice;
