"use client";

import { useContext } from "react";

import {
  ProductBadgeStyleEnum,
  TopBannerStyleEnum,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";
import findMicrositeApp from "@utils/microsites/personalization/findMicrositeApp";
import {
  Apps,
  PersonalizationAppPromotion,
} from "@utils/microsites/types/personalization";

const usePromotionPersonalization = (): PersonalizationAppPromotion => {
  const { personalization } = useContext(CampaignSuperfiliateMicrositeContext);

  return (
    (findMicrositeApp(
      personalization,
      Apps.Promotion,
    ) as PersonalizationAppPromotion) || {
      __typename: "PersonalizationsMicrositeAppsPromotion",
      kind: "promotions",
      enabled: false,
      heroBadgeDisplay: false,
      heroBadgeText: "",
      productBadgeDisplay: false,
      productBadgeStyle: ProductBadgeStyleEnum.Static,
      productBadgeText: "",
      productCardDisplay: false,
      productCardTitle: "",
      topBannerDisplay: false,
      topBannerStyle: TopBannerStyleEnum.Static,
      topBannerItems: [],
      topBannerText: "",
    }
  );
};

export default usePromotionPersonalization;
