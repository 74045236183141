"use client";

import React, { createContext, ReactNode, useContext } from "react";

export { SafeString } from "@utils/mustache";
import { mustacheReplacer, SafeString } from "@utils/mustache";

export const Context = createContext<Record<string, unknown>>({});

interface MustacheContentProps extends React.HTMLAttributes<HTMLInputElement> {
  // Related to avoiding possible XSS using this helper. Don't use a simple string type here.
  htmlTemplate?: SafeString | null;
  className?: string;
  fallback?: string | ReactNode;
  ignoreMissingVariables?: string[];
}

export const useMustacheContent = ({
  htmlTemplate,
  fallback,
  ignoreMissingVariables = [],
}: MustacheContentProps): string => {
  const variables = useContext(Context);

  return mustacheReplacer({
    htmlTemplate,
    fallback,
    ignoreMissingVariables,
    variables,
  });
};

export const Content: React.FC<
  React.PropsWithChildren<MustacheContentProps>
> = ({
  htmlTemplate: originalHtmlTemplate,
  fallback,
  className,
  ignoreMissingVariables = [],
}) => {
  const htmlTemplate = useMustacheContent({
    htmlTemplate: originalHtmlTemplate,
    fallback,
    ignoreMissingVariables,
  });

  if (!htmlTemplate && fallback && className) {
    return <div className={className}>{fallback}</div>;
  }

  if (!htmlTemplate && fallback) {
    return <>{fallback}</>;
  }

  if (!htmlTemplate) return null;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlTemplate,
      }}
      className={className}
    />
  );
};

const bundle = {
  Context,
  Content,
  useMustacheContent,
};

export default bundle;
