import {
  Apps,
  Personalization,
  PersonalizationApp,
  PersonalizationAppCart,
  PersonalizationAppPromotion,
  PersonalizationAppStickyAddToCart,
} from "../types/personalization";

export type { PersonalizationAppCart } from "../types/personalization";
export { Apps } from "../types/personalization";

function findMicrositeApp(
  personalization: Personalization | undefined | null,
  key: Apps.Cart,
): PersonalizationAppCart | null;
function findMicrositeApp(
  personalization: Personalization | undefined | null,
  key: Apps.Promotion,
): PersonalizationAppPromotion | null;
function findMicrositeApp(
  personalization: Personalization | undefined | null,
  key: Apps.StickyAddToCart,
): PersonalizationAppStickyAddToCart | null;
function findMicrositeApp(
  personalization: Personalization | undefined | null,
  key: Apps,
): PersonalizationApp | null {
  const personalizationApps = personalization?.microsite?.apps || [];
  const apps = personalizationApps.filter((app) => app.__typename === key);

  const app = apps[0];

  return app || null;
}

export default findMicrositeApp;
