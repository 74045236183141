import { useContext } from "react";

import { ShopifyProductVariant } from "@superfiliate/graphql-sdk/src/lib/__generated__/internal";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts";

interface ProductWithVariants {
  id: string;
  shopifyProductId: string;
  variants: ShopifyProductVariant[];
}

/*
  This hook is used to get the eligible product variants from the current active campaign reward
  and group them by product while keeping a field with all the variants of that product.
*/
const useEligibleProductsWithVariants = () => {
  const { campaignSuperfiliate } = useContext(
    CampaignSuperfiliateMicrositeContext,
  );
  const campaignReward = campaignSuperfiliate?.campaign?.campaignReward;
  const entitledVariants = campaignReward?.leadEntitledShopifyVariants;

  const productsWithVariants = entitledVariants?.reduce((acc, variant) => {
    const existingProduct = acc.find(
      (p) => p.shopifyProductId === variant?.shopifyProductId,
    );

    if (existingProduct) {
      existingProduct.variants.push(variant as ShopifyProductVariant);
    } else {
      acc.push({
        id: variant.shopifyProduct?.product?.id || "",
        shopifyProductId: variant?.shopifyProductId,
        variants: [variant as ShopifyProductVariant],
      });
    }

    return acc;
  }, [] as ProductWithVariants[]);

  return productsWithVariants || [];
};

export default useEligibleProductsWithVariants;
