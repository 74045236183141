// @ts-nocheck
//
// media-chrome is defined as a Web Component, so we intentionally ignore Typescript
// errors, given their interface is not properly set on the saved references

"use client";

import { MediaController } from "media-chrome/dist/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

import { MediaPlayerStates } from "@customer-ui/components/MuxMediaPlayer/types";
import MuxVideo from "@mux/mux-video-react";
import cn from "@utils/cn";
import isInsideIframe from "@utils/isInsideIframe";
import generateImageURL from "@utils/media/generateImageURL";
import generateVideoThumbnailURL from "@utils/media/generateVideoThumbnailURL";

export * from "@customer-ui/components/MuxMediaPlayer/types";

// Load media controller Web Components when using on iframe
setInterval(() => {
  if (typeof window === "undefined" || !window?.$embedded?.contentWindow)
    return;

  import("@utils/media/loadMediaController").then((module) => {
    module.default();
  });
}, 1000);

interface MuxMediaPlayerProps extends React.PropsWithChildren {
  /* The media style, required for styles matching */
  style: string;

  /* Media playback ID, either a random ID or an URL */
  playbackId: string;

  /* Type of media being served */
  contentType: string;

  /* Current state of the media player */
  state: MediaPlayerStates;

  /* The video className for additional style */
  className?: string;

  /* Custom thumbnail to be used as the video placeholder */
  thumbnail?: string | null;

  /* Callback - When media progress is complete */
  onEnded?: () => void;

  /* Flag - Loop the media on ending */
  loop?: boolean;

  /* Flag - Enable controls */
  controls?: boolean;
}

/*
 * For images, we play a void audio, with 5 seconds of duration, so we can
 * keep the same controls as a video element. When doing that, the poster will be
 * shown instead, so we achieve the expected behavior
 */
const IMAGES_PLACEHOLDER_URL =
  "1Oh66iZE01lkMaa02HJ01b2i01zfFrrLQ12VfPsb02U01YVl4";

const AUTO_PLAY_STATES = {
  [MediaPlayerStates.ACTIVE]: "muted",
  [MediaPlayerStates.ACTIVE_UNMUTED]: true,
  [MediaPlayerStates.PRELOADING]: false,
  [MediaPlayerStates.PREVIEWING]: false,
};

const MuxMediaPlayer: React.FC<MuxMediaPlayerProps> = ({
  style,
  playbackId,
  contentType,
  state,
  onEnded,
  className = "",
  thumbnail,
  loop = false,
  controls = false,
  children,
}) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const [previousState, setPreviousState] = useState(state);

  const intersection = useIntersection(ref, {
    root: null,
  });

  /*
   * We need to manually set the opacity to 1 after the video starts playing,
   * otherwise, on Safari/WebKit, the video will be shown with a poster of the
   * first frame, causing a UI glitch
   */
  const onPlaying = useCallback(() => {
    const mediaElement =
      ref.current?.media ||
      ref.current?.getElementsByTagName?.("video")?.[0] ||
      ref.current?.getElementsByTagName?.("audio")?.[0];

    if (!mediaElement) return;

    mediaElement.style.opacity = "1";
  }, [ref]);

  const synchronizeOnVisibilityChange = () => {
    if (
      !ref.current?.media ||
      !intersection ||
      state === MediaPlayerStates.ACTIVE_UNMUTED
    )
      return;

    if (intersection.isIntersecting) {
      ref.current.media.play?.();
    } else {
      ref.current.media.pause?.();
    }
  };

  useEffect(() => {
    if (!ref.current?.media) return;

    ref.current.media.style.opacity = "0";
  }, [playbackId]);

  useEffect(() => {
    if (!ref.current?.media) return;

    if (
      previousState === MediaPlayerStates.PRELOADING &&
      state === MediaPlayerStates.ACTIVE
    ) {
      ref.current.media.currentTime = 0;
      ref.current.media.play?.();
    }

    if (
      previousState === MediaPlayerStates.ACTIVE &&
      state === MediaPlayerStates.PRELOADING
    ) {
      ref.current.media.pause?.();
      setPlaybackStarted(false);
    }

    if (state === MediaPlayerStates.PRELOADING) ref.current.media.load?.();

    setPreviousState(state);
  }, [ref?.current?.media, state, previousState]);

  useEffect(synchronizeOnVisibilityChange, [
    ref?.current?.media,
    state,
    intersection,
  ]);

  if (!playbackId) return null;

  const isImage = contentType === "image";

  const parsedPlaybackID = isImage ? IMAGES_PLACEHOLDER_URL : playbackId;

  const mediaPoster = isImage
    ? generateImageURL(playbackId)
    : generateVideoThumbnailURL(playbackId);
  const poster = thumbnail || mediaPoster;

  const placeholder = generateImageURL(
    isImage ? playbackId : poster,
    "blur=75",
  );

  const autoPlay = AUTO_PLAY_STATES[state] || false;

  return (
    <div
      className={cn({
        "media-controller-preloading": state === MediaPlayerStates.PRELOADING,
      })}
      part="media-controller-container"
      style={{
        "--media-mute-button-display": isImage ? "none" : "inline-flex",
        ...(style === "fullScreenMedia"
          ? {}
          : {
              "--media-control-poster-background-image": `url('${poster}')`,
              "--media-control-blurred-background-image": `url('${placeholder}')`,
            }),
      }}
    >
      <MediaController ref={ref} kind={style} streamType="on-demand">
        {state !== MediaPlayerStates.PREVIEWING && (
          <MuxVideo
            key={playbackId}
            autoPlay={autoPlay}
            className={className}
            controls={controls}
            loop={loop}
            maxResolution="720p"
            playbackId={parsedPlaybackID}
            preload={isImage ? "none" : "auto"}
            slot="media"
            style={{ opacity: isInsideIframe() ? 1 : 0 }}
            playsInline
            onEnded={onEnded}
            onPlaying={onPlaying}
          />
        )}

        {/* Reserved for media player controls */}
        {children}
      </MediaController>
    </div>
  );
};

export default MuxMediaPlayer;
