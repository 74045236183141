"use client";

import { createContext } from "react";

import { CampaignSuperfiliateMicrositeContent } from "../types/microsite";

export const CampaignSuperfiliateMicrositeContext =
  createContext<CampaignSuperfiliateMicrositeContent>({
    isPreview: false,
    __typename: "CampaignSuperfiliateMicrosite",
    id: "0",
    activated: false,
    shop: {
      __typename: "Shop",
      id: "0",
      name: "Shop",
      domain: "superfiliate.com",
      shopifyDomain: "superfiliate.myshopify.com",
    },
    campaignSuperfiliate: {
      __typename: "CampaignSuperfiliate",
      code: "CODE",
      productVariant: "TODO",
      customer: "TODO",
      campaign: "TODO",
    },
    sections: "TODO",
    microsite: "TODO",
  } as unknown as CampaignSuperfiliateMicrositeContent);
