export enum Sections {
  Accordion = "PersonalizationsMicrositeSectionsAccordion",
  Dynamic = "PersonalizationsMicrositeSectionsDynamic",
  Header = "PersonalizationsMicrositeSectionsHeader",
  Footer = "PersonalizationsMicrositeSectionsFooter",
  Image = "PersonalizationsMicrositeSectionsImage",
  Marquee = "PersonalizationsMicrositeSectionsMarquee",
  MediaWithText = "PersonalizationsMicrositeSectionsMediaWithText",
  Product = "PersonalizationsMicrositeSectionsProduct",
  Review = "PersonalizationsMicrositeSectionsReview",
  ShowcaseImages = "PersonalizationsMicrositeSectionsShowcaseImages",
  Stories = "PersonalizationsMicrositeSectionsStories",
  Text = "PersonalizationsMicrositeSectionsText",
  Video = "PersonalizationsMicrositeSectionsVideo",
}

export enum Apps {
  Cart = "PersonalizationsMicrositeAppsCart",
  Promotion = "PersonalizationsMicrositeAppsPromotion",
  StickyAddToCart = "PersonalizationsMicrositeAppsStickyAddToCart",
}
