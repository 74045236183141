"use client";

import debounce from "lodash/debounce";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  ExternalMicrositeUpdateMicrositeCustomerSettingsInput,
  getSdk,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";
import useDebounced from "@utils/hooks/useDebounced";
import useIsMount from "@utils/hooks/useIsMount";
import isClient from "@utils/isClient";
import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import {
  mapToCustomerSettingStories,
  pickSectionStories,
} from "@utils/microsites/stories";
import { Sections } from "@utils/microsites/types/personalization";

import { CampaignSuperfiliateMicrositeContext } from "./campaignSuperfiliate";

export type CustomerSettings =
  ExternalMicrositeUpdateMicrositeCustomerSettingsInput;

export const defaultCustomerOnboardingSettings: CustomerSettings = {
  url: isClient ? window.location.href : "",
  intro: "",
  messages: [],
  stories: [],
  defaultStories: [],
};

export const CustomerOnboardingCustomizationContext = createContext<{
  customerSettings: CustomerSettings;
  loading: boolean;
  setCustomerSettings: (args: CustomerSettings) => void;
}>({
  customerSettings: defaultCustomerOnboardingSettings,
  loading: false,
  setCustomerSettings: () => {
    /**/
  },
});

/**
 * This context is intended to be used for templates that have the
 * digital builder editor, so the customer can customize the page style
 */
export const CustomerOnboardingCustomizationContextProvider: React.FC<
  PropsWithChildren<{
    sdk: ReturnType<typeof getSdk>;
  }>
> = ({ sdk, children }) => {
  const isMountRender = useIsMount();
  const campaignSuperfiliateMicrosite = useContext(
    CampaignSuperfiliateMicrositeContext,
  );

  const storiesSection = findMicrositeSection(
    campaignSuperfiliateMicrosite.personalization,
    Sections.Stories,
  );
  const productSection = findMicrositeSection(
    campaignSuperfiliateMicrosite.personalization,
    Sections.Product,
  );

  const stories =
    mapToCustomerSettingStories(pickSectionStories(storiesSection)) || [];
  const defaultStories =
    mapToCustomerSettingStories(storiesSection?.libraryCustomer || []) || [];

  const multiSkuProductVariantIds =
    productSection?.multiSkuAvailableProductVariantIds &&
    productSection?.multiSkuProductVariantIds;

  const pickedProductIds =
    productSection?.availableProductIds && productSection?.pickedProductIds;

  const url = isClient ? window.location.href : "";

  // Overwriting title and subtitle to come preferentially from personalization,
  // but fallback to customer settings if personalization is empty
  const title =
    storiesSection?.title || defaultCustomerOnboardingSettings.intro;

  const subtitle =
    storiesSection?.subtitle || defaultCustomerOnboardingSettings.messages?.[0];

  const [customerSettings, setCustomerSettings] = useState<CustomerSettings>({
    ...defaultCustomerOnboardingSettings,
    stories,
    defaultStories,
    intro: title,
    messages: [subtitle || ""],
    personalization: {
      multiSkuProductVariantIds,
      pickedProductIds,
    },
    url,
  });

  const debouncedCustomerSettings = useDebounced(customerSettings, 500);

  const [loading, setLoading] = useState(false);

  const updateSettings = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        await sdk.UpdateMicrositeCustomerSettingsMutation({
          input: { ...customerSettings },
        });
      } finally {
        setLoading(false);
      }
    }, 500),
    [debouncedCustomerSettings],
  );

  useEffect(() => {
    if (isMountRender) return;

    updateSettings();
  }, [debouncedCustomerSettings]);

  return (
    <CustomerOnboardingCustomizationContext.Provider
      value={{ customerSettings, loading, setCustomerSettings }}
    >
      {children}
    </CustomerOnboardingCustomizationContext.Provider>
  );
};
