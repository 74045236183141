import {
  AssetContent,
  PartialStory,
} from "../microsites/types/personalization";

const STORY = {
  uuid: "c2381920-9839-4018-95c6-a6d6abd3dcb2",
  alt: "Stories",
  url: "https://res.cloudinary.com/da2a4i71k/image/upload/v1669206155/internal/placeholder_ntdwsq.jpg",
  contentType: AssetContent.Image,
  duration: 5000,
  media: {
    mediaId: "7bb3b69c-a057-4dce-1103-bb4ea7f65a00",
    mediaProvider: "cloudflare",
    mediaType: "image",
  },
} as PartialStory;

export default STORY;
