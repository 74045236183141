// "myExampleString" -> "my_example_string"
const camelToSnakeCase = (key: string) => {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

export const camelToSnakeCaseHash = (
  hash: Record<string, unknown>,
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(hash).map(([a, b]) => [camelToSnakeCase(a), b]),
  );

export default camelToSnakeCase;
