import PostHogSDK, { PostHog } from "posthog-js";
export { PostHog } from "posthog-js";

export { PORTAL_EVENTS } from "./events";


let posthogInstance: null | PostHog = null;
export const getPosthog = () => posthogInstance;

export default getPosthog;

export const APPS = {
  storefront: {
    id: "storefront",
    name: "Storefront",
  },
  microsite: {
    id: "microsite",
    name: "Microsite",
  },
  portal: {
    id: "portal",
    name: "Portal",
  },
};

interface InitPosthogOptions {
  onLoaded?: (sdk: PostHog) => void;
  recordSession?: boolean;
  autocapture?: boolean;
}

export const initPosthog = (
  apiKey: string,
  appId: keyof typeof APPS,
  initOptions?: InitPosthogOptions,
) => {
  if (!apiKey) return;
  if (process.env.NODE_ENV === "development") return;

  const {
    onLoaded,
    recordSession = true,
    autocapture = true,
  } = initOptions || {};

  PostHogSDK.init(
    apiKey,
    {
      api_host: "https://app.posthog.com",
      // Use localStorage, so we don't have customers from different shops getting merged
      // https://www.loom.com/share/668e208f113e4ae2b8b483edf6e61a00
      persistence: "localStorage",
      autocapture,
      capture_pageview: autocapture,
      loaded: (newPostHogSDK: PostHog) => {
        posthogInstance = newPostHogSDK;

        if (
          window.location.protocol !== "https:" ||
          process.env.NODE_ENV === "development"
        ) {
          // posthogInstance.debug();
          // posthogInstance.opt_out_capturing();
          // Uncomment the line below in case you would like to
          // start capturing real events in the development project
          // posthogInstance.opt_in_capturing();
        }

        posthogInstance.group("app", appId, APPS[appId]);

        if (onLoaded) {
          onLoaded(posthogInstance);
        }

        if (recordSession) {
          posthogInstance.startSessionRecording();
        }
      },
    },
    appId,
  );
};

export const getUserIdentifier = (
  email: string,
  shopId: string | number,
): string => `shop:${shopId};${email}`;

export const isUserIdentified = (posthog: PostHog): boolean => {
  try {
    return posthog.get_distinct_id().startsWith("shop:");
  } catch {
    return false;
  }
};

export const identifyUser = (
  posthog: PostHog,
  email: string,
  shopId: string | number,
  customerData?: object,
): void => {
  if (isUserIdentified(posthog)) {
    return;
  }

  posthog.identify(getUserIdentifier(email, shopId), customerData);
};
