import urlcat from "urlcat";

/**
 * Accepts a media ID connected to a video and returns
 * a thumbnail URL to the video (i.e. the WEBP equivalent)
 *
 * @example
 *
 * generateVideoThumbnailURL("awds") => "https://image.mux.com/awds/thumbnail.webp?time=0"
 *
 * @param mediaID - The media ID of the video
 * @param time - The time in seconds to generate the thumbnail from
 * @param format - The format of the thumbnail (defaults to WEBP)
 */
const generateVideoThumbnailURL = (
  mediaID: string,
  time?: number,
  format?: string,
): string => {
  return urlcat(
    `https://image.mux.com/:mediaID/thumbnail.${format || "webp"}`,
    {
      mediaID,
      time: time || 0,
    },
  );
};

export default generateVideoThumbnailURL;
